import React from "react";
import styled from "styled-components";

const EventView = () => {
    return (
        <Container>
            <Loader>
                <Span el="4"></Span>
                <Span el="3"></Span>
                <Span el="2"></Span>
                <Span el="1"></Span>
                <Span el="0"></Span>
            </Loader>
        </Container>
    );
};

export default EventView;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #939393;
    overflow: hidden;
`;

const Loader = styled.div`
    position: relative;
    transform: skewY(-15deg) translateX(50px) translateY(50px);
    transform-style: preserve-3d;
    animation: animateColor 2.5s linear infinite;
    @keyframes animateColor {
        0% {
            filter: hue-rotate(0deg);
        }
        100% {
            filter: hue-rotate(360deg);
        }
    }
    &::before {
            content: "";
            position: absolute;
            top: 200px;
            width: 200px;
            height: 200px;
            background: rgba(0, 0, 0, 0.1);
            transform-origin: bottom;
            transform: skewX(45deg);
            filter: blur(20px);
        }
`;
const Span = styled.span`
    position: relative;
    width: 100px;
    background: #3e3f46;
    height: 40px;
    z-index: ${(props) => props.el};
    transition: 0.5s;
    display: block;
    animation: animate 6.5s ease-in-out infinite;
    animation-delay: ${(props) => `calc(-1s * ${props.el})`};

    @keyframes animate {
        0% {
            transform: translateX(-70px);
        }
        50% {
            transform: translateX(70px);
        }
        100% {
            transform: translateX(-70px);
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -70px;
        width: 70px;
        height: 100%;
        background: #2e3133;
        transform-origin: right;
        transform: skewY(45deg);
        transition: 0.5s;
    }
    &::after {
        content: "";
        position: absolute;
        top: -70px;
        left: 0;
        width: 100%;
        height: 70px;
        background: #35383e;
        transform-origin: bottom;
        transform: skewX(45deg);
        transition: 0.5s;
    }

    &:hover {
        background: #33a3ee;
        transition: 0s;
    }
    &:hover::before {
        background: #1f5378;
        transition: 0s;
    }
    &:hover::after {
        background: #2982b9;
        transition: 0s;
    }
`;
