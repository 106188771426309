import React from "react";
import EventView from "../views/EventView";

const Event = () => {
    return (
        <>
            <EventView />
        </>
    );
};

export default Event;
